import React, { ReactNode } from 'react';
import cn from "classnames";

type Props = {
    name: string;
    children: ReactNode;
    onChange: (event: any) => void;
    className?: string;
    value?: any;
};

export default function Select(props: Props) {
    const { name, onChange, value } = props;
    // const defaultValue = props.defaultValue === undefined ? null : props.defaultValue;

    const className = cn(
        props.className && props.className
    );

    return (
        <select name={name} onChange={onChange} className={className} value={value}>
            {props.children}
        </select>
    );
}