import React, { ReactNode } from 'react';
import cn from "classnames";
import { IThemes } from "types/base";
import { backgroundColor, backgroundColorContainer } from "classes";

type Props = {
    className?: string;
    theme?: IThemes;
    isRounded?: boolean;
    children: ReactNode;
    style?: any;
    overflowable?: boolean;
};

function RoundedContainer(props: Props) {

    const containerCn = cn(
        props.className ?? "",
        `mx-auto px-4 sm:px-0 container`,
    );

    const classNames = cn(
        backgroundColorContainer(props.theme),
        'z-40 rounded-3xl lg:m-4 lg:mx-8 lg:mt-4 lg:mb-12',
    );

    return (
        <div className={"lg:px-0"}>
            <div className={containerCn}>
                <div className={classNames}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

function FluidContainer(props: Props) {
    const containerCn = cn(
        props.className ?? "",
        props.overflowable ? "" : "overflow-hidden",
        `mx-auto container`,
    );

    return (
        <div className={backgroundColor(props.theme)} style={props.style}>
            <div className={containerCn}>
                {props.children}
            </div>
        </div>
    );
}

function Container(props: Props) {

    if (props.isRounded) {
        return (
            <RoundedContainer {...props}>
                {props.children}
            </RoundedContainer>
        );
    }

    return (
        <FluidContainer {...props}>
            {props.children}
        </FluidContainer>
    );
}

Container.defaultProps = {
    background: "default"
};

export default Container;
