import React from 'react';
import FooterSitemap from "components/footer/FooterSitemap";
import FooterBar from "components/footer/FooterBar";
import Container from "components/core/Container";
import FooterCTA from "components/footer/FooterCTA";
import { IButton, ILink } from "types/base";
import cn from "classnames";

export type IFooterColumn = {
    id: string;
    label: string,
    items: IFooterItem[]
};

export type IFooterItem = {
    id: string;
    label: string,
    description: string;
    link: ILink;
};

export type IFooter = {
    slogan: string,
    button: IButton[],
    columns: IFooterColumn[],
    bottom: IFooterItem[],
};

export default function Footer(props: IFooter) {

    const footerContainerCn = cn(
        "pt-12 lg:pt-16",
        "lg:w-full lg:grid lg:grid-cols-4",
    );
    return (
        <footer>
            <Container>
                <div className={footerContainerCn}>
                    <FooterCTA {...props} />
                    <FooterSitemap {...props} />
                </div>
            </Container>
            <FooterBar items={props.bottom} />
        </footer>
    );
}