import NextLink from "next/link";
import React, { ReactNode } from "react";
import Anchor from "components/elements/Anchor";
import { ILink } from "types/base";
import useRouter from "components/hooks/useRouter";

type Props = {
    children: ReactNode;
    to?: string;
    style?: any;
    className?: string;
    params?: Object;
    __typename?: string;
    openNewTab?: boolean;
};

const parseSlugFromPage = (page: { slug: string, __typename: string }) => {

    // This is a hot fix! We need to rethink, how to handle nested slugs.
    if (page?.__typename == "FormPageRecord") {
        return `/forms/${page.slug}`;
    }

    // This is a hot fix! We need to rethink, how to handle nested slugs.
    if (page?.__typename == "CustomerStoryRecord") {
        return `/customers/${page.slug}`;
    }

    // This is a hot fix! We need to rethink, how to handle nested slugs.
    if (page?.__typename == "LegalSectionRecord") {
        return `/legal/${page.slug}`;
    }

    // This is a hot fix! We need to rethink, how to handle nested slugs.
    if (page?.__typename == "BlogPostRecord") {
        return `/blog/${page.slug}`;
    }

    // This is a hot fix! We need to rethink, how to handle nested slugs.
    if (page?.__typename == "GlossaryEntryRecord") {
        return `/glossary/${page.slug}`;
    }

    // This is a hot fix! We need to rethink, how to handle nested slugs.
    if (page?.__typename == "AgencyRecord") {
        return `/pricing/${page.slug}`;
    }

    if (page?.slug) {
        return `/${page.slug}`;
    }

    return null;
};

export default function Link(props: Props & Partial<ILink>) {
    const { locale } = useRouter();
    const { isExternalLink, externalUrl, page, params, openNewTab } = props;

    let to = isExternalLink ? externalUrl : parseSlugFromPage(page);

    if (props.to) {
        to = props.to;
    }

    if (isExternalLink) {
        return (
            <Anchor to={to ? to : '/'} className={props.className} openNewTab={openNewTab}>
                {props.children}
            </Anchor>
        );
    }

    const getHref = (): any => {
        if (to) {
            if (params) {
                return {
                    pathname: to,
                    query: params
                };
            }

            return {
                pathname: to,
            };
        }
        return "/";
    };

    return (
        <NextLink href={getHref()} locale={locale} passHref={true} className={props.className}>
            {props.children}
        </NextLink>
    );
}
