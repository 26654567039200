import { useEffect } from "react";

export default function useAutoClose(ref: any, callback: any, updateCondition: boolean) {

    function handleHoverOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target) && updateCondition) {
            callback();
        }
    }

    useEffect(() => {
        document.addEventListener("click", handleHoverOutside);
        return () => {
            document.removeEventListener("click", handleHoverOutside);
        };
    }, [ref, updateCondition]);
}