import { GraphQLClient } from "graphql-request";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import getConfig from "next/config";
import { HeaderQuery } from "queries/Header";
import { FooterQuery } from "queries/Footer";
import * as Dom from "graphql-request/dist/types.dom";

const { publicRuntimeConfig } = getConfig();

export async function request({
                                  query,
                                  variables,
                                  preview,
                                  withAppData,
                                  locale
                              }: { query: any, variables?: Object, preview?: boolean, withAppData?: boolean, locale?: string }) {
    const endpoint = preview
        ? `https://graphql.datocms.com/environments/${publicRuntimeConfig.DATOCMS_ENVIRONMENT}/preview`
        : `https://graphql.datocms.com/environments/${publicRuntimeConfig.DATOCMS_ENVIRONMENT}`;


    if (withAppData) {
        query = {
            ...query,
            ...HeaderQuery(locale),
            ...FooterQuery(locale)
        };
    }

    const queryToLog = query;

    query = jsonToGraphQLQuery({
        query: query
    });

    const client = new GraphQLClient(endpoint, {
        headers: {
            authorization: `Bearer ${publicRuntimeConfig.DATOCMS_API_TOKEN}`
        }
    });

    const request = await client.rawRequest(query, variables);

    checkHeadersForComplexity(request.headers, queryToLog);

    return request.data;
}

function checkHeadersForComplexity(headers: Dom.Headers, query: string) {

    const complexity = parseInt(headers.get('x-complexity'));
    const maxComplexity = parseInt(headers.get('x-max-complexity'));

    if (complexity > maxComplexity) {
        console.error(`Max complexity reached! | complexity: ${complexity} | query: \n`);
    }
}
