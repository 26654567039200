import React from "react";
import cn from "classnames";
import { fontWeight } from "classes";
import { ILink, IText, ITextAlignment, ITextSize, ITextWeight, IThemes } from "types/base";
import { renderRule, StructuredText } from "react-datocms";
import { isLink, isList, isListItem, isParagraph } from "datocms-structured-text-utils";
import Anchor from "components/elements/Anchor";

type IParagraphs = "structuredText" | "paragraph";

type Props = {
    children: any;
    renderAs?: IParagraphs;
    size?: ITextSize;
    alignment?: ITextAlignment;
    fontWeight?: ITextWeight;
    className?: string;
    theme?: IThemes;
    color?: string;
    link?: ILink[]

};

function Paragraph(props: Props & IText) {
    const { renderAs } = props;

    const paragraphCn = cn(
        fontWeight(props.fontWeight),
        props.transform ?? null,
        props.className ?? null,
    );

    if (renderAs == "structuredText") {
        return (
            <StructuredText
                data={props.children}
                customRules={[
                    renderRule(
                        isParagraph,
                        ({ adapter: { renderNode }, node, children, key, ancestors }) => {
                            return (
                                <p
                                    key={key}
                                    className={`structured-text ${paragraphCn ?? ''} ${props.alignment == 'center' ? 'mx-auto' : ''} `}
                                >
                                    {children}
                                </p>
                            );
                        }
                    ),
                    renderRule(
                        isList,
                        ({ adapter: { renderNode }, node, children, key, ancestors }) => {
                            return (
                                <ul
                                    key={key}
                                    className={`my-4 pl-2 lg:pl-4 text-base lg:text-lg ${props.alignment == 'center' ? 'w-max mx-auto' : ''}`}
                                >
                                    {children}
                                </ul>
                            );
                        }
                    ),

                    renderRule(
                        isLink,
                        ({ adapter: { renderNode }, node, children, key, ancestors }) => {
                            return (
                                <Anchor
                                    key={key}
                                    className={'text-primary-600'}
                                    to={node.url}
                                >
                                    {children}
                                </Anchor>
                            );
                        }
                    ),

                    renderRule(
                        isListItem,
                        ({ adapter: { renderNode }, node, children, key, ancestors }) => {
                            return (
                                <li className={"mb-4 xl:mb-5"} key={key}>
                                    <div className={`flex flex-row items-center`}>
                                        <div>
                                            <svg
                                                width="19"
                                                height="20"
                                                className={'mr-3 w-7 h-7'}
                                                viewBox="0 0 19 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <rect y="0.286621" width="19" height="19" rx="9.5" fill="#3430FF"/>
                                                <rect
                                                    x="4.37207"
                                                    y="9.14014"
                                                    width="2.01491"
                                                    height="6.13206"
                                                    rx="1.00746"
                                                    transform="rotate(-37.7939 4.37207 9.14014)"
                                                    fill="white"
                                                />
                                                <rect
                                                    x="12.9346"
                                                    y="5.20068"
                                                    width="2.01491"
                                                    height="9.77813"
                                                    rx="1.00746"
                                                    transform="rotate(35.0307 12.9346 5.20068)"
                                                    fill="white"
                                                />
                                            </svg>
                                        </div>
                                        <div>
                                            {children}
                                        </div>
                                    </div>
                                </li>
                            );
                        }
                    )
                ]}
            />
        );
    }

    return (
        <p className={paragraphCn}>
            
            {props.children}
        </p>
    );
}

Paragraph.defaultProps = {
    renderAs: "paragraph",
    size: "medium",
    alignment: "left",
    fontWeight: "medium"
};

export default Paragraph;