import React from "react";
import { IFooter } from "components/footer/Footer";
import Link from "components/base/Link";
import cn from "classnames";

export default function FooterSitemap(props: IFooter) {

    const footerContainerCn = cn(
        "gap-x-8 grid grid-cols-2 ",
        "md:grid md:grid-cols-3 md:col-span-3",
        "lg:pt-0 lg:ml-12 xl:ml-16",
    );

    return (
        <div className={footerContainerCn}>
            {props.columns?.map((f) => {
                return (
                    <div key={f.label} className={"pb-12"}>
                        <h2 className={"text-gray-400 pb-5 text-sm font-normal-medium pb-4"}>{f.label}</h2>
                        <ul>
                            {f.items?.map((item) => {
                                const link = item.link[0];
                                return (
                                    <li
                                        key={item.label}
                                        className={"pb-3.5 text-base text-gray-500 font-normal pb-3 last:pb-0"}
                                    >
                                        <Link to={link.isExternalLink ? `${link.externalUrl}` : link.page?.slug ? `/${link.page.slug}` : '/'} children={item.label} className={"hover:text-purple hover:underline"} />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
}

