import React, { ReactNode } from "react";
import cn from "classnames";
import { IButton, IButtonSizes, IButtonVariants, IIcon } from "types/base";
import Icon from "components/elements/Icon";

type Props = {
    id: number | string;
    variant?: IButtonVariants;
    size: IButtonSizes;
    icon?: IIcon,
    children: ReactNode;
    disabled?: boolean;
    className?: string;
    pending?: boolean;
    onClick?: () => void;
    isSubmit?: boolean;
};

export default function Button(props: Props & Partial<IButton>) {
    const { variant, size, onClick, icon, isSubmit } = props;
    const disabled = props.disabled === undefined ? false : props.disabled;
    const isLink = variant == "link" || variant == "white-link";

    const sizeCn = cn(
        { 'py-2.5 px-0': isLink },
        { 'py-1.5 px-1.5 text-xs mt-4': size == "extra-small" && !isLink },
        { 'py-2 px-3': size == "small" && !isLink },
        { 'py-2 px-3': size == "medium" && !isLink },
        { 'py-3.5 md:px-5 px-8 text-lg': size == "large" && !isLink },
    );

    const borderRadiusCn = cn(
        { 'rounded': size == "extra-small" },
        { 'rounded-lg': size == "small" },
        { 'rounded-xl': size == "medium" },
        { 'rounded-xl': size == "large" },
    );

    const variantCn = cn(
        { 'text-white bg-primary-600 hover:bg-primary-700 active:bg-primary-900 border border-primary-600': variant == "primary" },
        { 'text-primary-600 bg-primary-25 hover:bg-primary-50 active:bg-primary-200 border border-gray-50': variant == "secondary" },
        { 'text-gray-700 bg-white hover:bg-gray-25 active:bg-gray-100 border border-primary-50': variant == "ghost" },
        { 'text-primary-600 hover:text-primary-700': variant == 'link' },
        { 'text-white hover:text-gray-200': variant == 'white-link' }
    );

    const disabledCn = cn(
        { 'text-opacity-50 cursor-not-allowed': props.disabled }
    );

    const className = cn(
        'w-full flex items-center whitespace-no-wrap transition-all group duration-300',
        isLink ? "justify-start" : "justify-center",
        { 'opacity-80': props.pending },
        sizeCn,
        borderRadiusCn,
        variantCn,
        disabledCn,
        props.className,
    );

    const hoverStatesCnIcon = cn(
        { 'bg-primary-600 group-hover:bg-primary-700': variant == "link" },
        { 'bg-white group-hover:bg-gray-200': variant == "white-link" },
    );

    return (
        <button
            className={`ga__button ${className}`}
            type={isSubmit ? "submit" : "button"}
            disabled={disabled}
            onClick={onClick}
            id={props.id}
        >
            {props.children}
            {icon && (
                <Icon
                    url={icon.source.url}
                    className={`ml-1.5 mt-0.5 ${hoverStatesCnIcon} ${!disabled && 'group-hover:translate-x-1 transform ease-in transition-transform duration-300'}`}
                    size={size}
                />
            )}
        </button>
    );
}