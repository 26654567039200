import React from 'react';
import { animated, useSpring } from "@react-spring/web";
import Heading from "components/elements/Heading";
import Paragraph from "components/elements/Paragraph";
import Link from "components/base/Link";
import Switch from "components/elements/form/switch";
import Button from "components/elements/Button";
import useTrans from "components/hooks/useTrans";
import { PiggyCookies } from "components/cookieConsent/CookieConsent";
import cn from "classnames";

type Props = {
    isActive: boolean;
    setActive: (isActive: boolean) => void;
    save: any;
    setCookies: any;
    cookies: PiggyCookies;
};

export default function Manage(props: Props) {
    const { isActive, setActive, save, cookies, setCookies } = props;
    const { t } = useTrans();

    const opacityMobile = useSpring({
        from: { opacity: 0, height: "0" },
        to: { opacity: isActive ? 0.4 : 0, height: isActive ? "1000vh" : "0" }
    });

    const opacityDesktop = useSpring({
        from: { opacity: 0, height: "0" },
        to: { opacity: isActive ? 1 : 0, height: isActive ? "1000vh" : "0" }
    });

    const animation = useSpring({
        from: { top: -700 },
        to: { top: isActive ? 150 : -700 }
    });

    const handleOptionChange = (cookieType: string, disabled?: boolean) => {
        if(disabled) {
            return;
        }

        setCookies((prevState: PiggyCookies) => ({
            ...prevState,
            [cookieType]: !cookies[cookieType]
        }));
    };

    const modalContainerCn = cn(
        "fixed flex flex-col justify-between p-3 bg-white z-50 w-full",
        "md:inset-x-1/4 md:w-auto md:rounded-2xl",
        "lg:p-8",
        "xl:inset-x-1/3",
    );

    const overlayCn = cn(
        "h-screen w-screen absolute top-0 bg-white",
        "md:bg-purple-dark",
        isActive ? "z-50" : "z-0"
    );

    const renderOptions = () => {
        return Object.keys(cookies).map((cookie) => {
            const isDisabled = cookie === "essential";
            return (
                <div className={'mb-4 flex flex-row justify-between'} key={cookie}>
                    <div>
                        <div>{t(`${cookie}CookieTitle`)}</div>
                        <Paragraph
                            size={'small'}
                            fontWeight={'light'}
                            className={'text-purple-dark opacity-50 max-w-sm'}
                        >
                            {t(`${cookie}CookieDescription`)}
                        </Paragraph>
                    </div>
                    <Switch
                        onChange={() => handleOptionChange(cookie, isDisabled)}
                        checked={cookies[cookie]}
                        disabled={isDisabled}
                    />
                </div>
            );
        });
    };

    return (
        <>
            <animated.div
                style={opacityMobile}
                className={`${overlayCn} hidden md:inline-block`}
            />

            <animated.div
                style={opacityDesktop}
                className={`${overlayCn} inline-block md:hidden`}
            />

            <animated.div
                style={animation}
                className={modalContainerCn}
            >

                <div className={'pb-2 mb-2 w-11/12'}>
                    <Heading renderAs={'h3'}>
                        {t('manageCookiesHeading')}
                    </Heading>
                    <Paragraph fontWeight={'light'} className={'text-purple-dark opacity-60 pb-2'}>
                        {t('cookiesDescription')}
                        <Link className={'opacity-100 text-purple'}> Learn more.</Link>
                    </Paragraph>
                </div>

                <div className={'pt-4 border-t-2 border-purple-tint-light'}>
                    {renderOptions()}
                </div>

                <div className={'flex flex-col space-y-2 border-t-2 pt-6 border-purple-tint-light'}>
                    <Button
                        id={"manage-cookies__accept-all"}
                        size={'large'}
                        color={'white'}
                        variant={'secondary'}
                        className={'bg-green justify-center'}
                        onClick={() => {
                            save();
                            setActive(!isActive);
                        }}
                    >
                        {t('acceptAllCookies')}
                    </Button>
                    <Button
                        id={"manage-cookies__save-settings"}
                        size={'large'}
                        color={'black'}
                        variant={'ghost'}
                        className={'bg-purple-tint-light border-none justify-center hover:border-2 '}
                        onClick={() => {
                            save();
                            setActive(!isActive);
                        }}
                    >
                        {t('saveSettings')}
                    </Button>
                </div>
            </animated.div>
        </>
    );
}