import { useQuerySubscription } from "react-datocms";
import cn from "classnames";
import React from "react";
import { GetStaticPropsContext } from "next";
import { request } from "components/hooks/useQuery";
import getConfig from "next/config";
import { jsonToGraphQLQuery } from "json-to-graphql-query";
import LanguageSwitcher from "components/base/LanguageSwitcher";
const { publicRuntimeConfig } = getConfig();

export async function createSubscription(context: GetStaticPropsContext, queryObject: any) {
    let subscription = ({
        initialData: await request(queryObject),
        token: publicRuntimeConfig.DATOCMS_API_TOKEN,
    });

    queryObject.query = jsonToGraphQLQuery({
        query: queryObject.query
    });

    if(context.preview) {
        subscription = {
            ...subscription,
            ...queryObject
        };
    }

    return subscription;
}

export const withPreview = (Component: any) => (props: any) => {
    const { data, error, status } = useQuerySubscription(props);

    const statusMessage = {
        connecting: 'Connecting to DatoCMS...',
        connected: 'Connected to DatoCMS, receiving live updates!',
        closed: 'Connection closed',
    };

    const statusCn = cn(
        { "bg-green": status == "connected" },
        { "bg-yellow": status == "connecting" },
        { "bg-red": status == "closed" },
        "h-10 w-full fixed top-0 text-purple-dark flex justify-center items-center z-50"
    );

    return (
        <>
            <div className={statusCn}>
                <div className={"left-12 absolute hover:underline text-xs"}>
                    <a href={"https://website-piggy.admin.datocms.com"}>Go to DatoCMS</a>
                </div>

                <h3>{statusMessage[status]}</h3>

                <div className={"ml-5"}>
                    <LanguageSwitcher />
                </div>

                <div className={"right-12 absolute hover:underline text-xs"}>
                    <a href={"/api/exit-preview"}>Exit preview mode</a>
                </div>
            </div>

            <Component {...data} />
        </>
    );
};
