import React, { useEffect, useRef, useState } from 'react';
import Manage from "components/cookieConsent/Manage";
import { useCookies } from "react-cookie";
import Flag from "components/cookieConsent/Flag";

type Props = {};
export type PiggyCookies = {
    essential: boolean,
    analytics: boolean,
    marketing: boolean
};

export default function CookieConsent(props: Props) {
    const [cookies, setCookies, removeCookie] = useCookies(["_piggy_cc"]);
    const [cookieConsentGiven, setCookieConsentGiven] = useState(false);

    const [modalActive, setModalActive] = useState(false);
    const [flagActive, setFlagActive] = useState(true);

    const [appCookies, setAppCookies] = useState({
        essential: true,
        analytics: true,
        marketing: true
    });

    useEffect(() => {
        if (!window.dataLayer) {
            return;
        }

        if (cookies._piggy_cc) {
            if (cookies._piggy_cc.essential) {
                window.dataLayer.push({ "event": "essential_cookies_active" });
            } else {
                window.dataLayer.push({ "event": "essential_cookies_inactive" });
            }

            if (cookies._piggy_cc.analytics) {
                window.dataLayer.push({ "event": "analytical_cookies_active" });
            } else {
                window.dataLayer.push({ "event": "analytical_cookies_inactive" });
            }

            if (cookies._piggy_cc.marketing) {
                window.dataLayer.push({ "event": "marketing_cookies_active" });
            } else {
                window.dataLayer.push({ "event": "marketing_cookies_inactive" });
            }
            setCookieConsentGiven(true);
        }
    }, [cookies]);
    
    const getExpiresIn = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth();
        const day = today.getDay();

        return new Date(year + 1, month, day);
    };

    const setCookieSettings = () => {
        setCookieConsentGiven(true);
        setCookies("_piggy_cc", appCookies, { path: "/", expires: getExpiresIn() });
    };

    return (
        <div hidden={cookieConsentGiven}>
            <Flag
                isFlagActive={flagActive}
                setFlagActive={setFlagActive}
                setModalActive={setModalActive}
                save={setCookieSettings}
            />
            <Manage
                isActive={modalActive}
                setActive={setModalActive}
                save={setCookieSettings}
                cookies={appCookies}
                setCookies={setAppCookies}
            />
        </div>
    );
}