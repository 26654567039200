import React from 'react';
import { INavigationItem } from "components/header/Header";
import Link from "components/base/Link";
import cn from "classnames";
import Icon from "components/elements/Icon";

type Props = {
    onClick: any;
    hasChildren: boolean;
};

export default function NavigationItem(props: Props & INavigationItem) {
    const { onClick, isExternalLink, externalUrl, page, label, hasChildren } = props;
    const isLink = isExternalLink || externalUrl || page;

    const buttonCn = cn(
        "text-base py-4 border-b border-purple-tint-light w-full text-left flex justify-between items-center navigation__link pr-1.5 hover:bg-primary-25"
    );

    if (isLink) {
        return (
            <Link isExternalLink={isExternalLink} externalUrl={externalUrl} page={page}>
                <button
                    type={"button"}
                    className={buttonCn}
                >
                    <span>
                        {label}
                    </span>
                    {hasChildren &&
                        <Icon
                            url={"/static/icons/chevron-right.svg"}
                            className={'stroke-current bg-purple-dark'}
                            size={"extra-small"}
                        />
                    }
                </button>
            </Link>
        );
    }

    return (
        <button
            type={"button"}
            className={buttonCn}
            onClick={onClick}
        >
            <span>
                {label}
            </span>
            {hasChildren &&
                <Icon
                    url={"/static/icons/chevron-right.svg"}
                    className={'stroke-current bg-purple-dark'}
                    size={"extra-small"}
                />
            }
        </button>
    );
}
