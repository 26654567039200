import React from 'react';
import { useCookies } from "react-cookie";
import useRouter from "components/hooks/useRouter";
import cn from "classnames";
import Select from "components/elements/form/select/Select";
import Option from "components/elements/form/select/Option";

type ISize = "medium" | "large" | "small";

type Props = {
    size?: ISize;
};

export default function LanguageSwitcher(props: Props) {
    const { locale, push, pathname, asPath } = useRouter();
    const [cookies, setCookie] = useCookies(['NEXT_LOCALE']);

    const changeLanguage = (event: any) => {
        setCookie("NEXT_LOCALE", event.target.value);
        push(pathname, asPath, { locale: event.target.value });
    };

    const selectSize = cn(
        "bg-white",
        { "text-lg": props.size == "medium" },
        { "text-3xl": props.size == "large" },
    );

    return (
        <>
            <Select name={"language-switcher"} value={locale} onChange={changeLanguage} className={selectSize}>
                <Option label={"🇬🇧"} value={"en"}/>
                <Option label={"🇳🇱"} value={"nl"}/>
                <Option label={"🇩🇪"} value={"de"}/>
            </Select>
        </>
    );
}

