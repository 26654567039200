import React from 'react';
import { INavMenuItem } from "components/header/Header";
import Text from "components/typography/Text";
import Icon from "components/elements/Icon";
import Link from "components/base/Link";
import useRouter from "components/hooks/useRouter";
import cn from "classnames";

type Props = {
    item: INavMenuItem;
    totalAmountOfColumns: number;
};

export default function NavMenuItem(props: Props) {
    const { item, totalAmountOfColumns } = props;
    const { asPath } = useRouter();
    const isActive = item?.link[0]?.page?.slug !== asPath.substring(1);
    const widthOfNavMenuItemCn = cn(
        { 'w-60': totalAmountOfColumns == 3 },
        { 'w-96': totalAmountOfColumns == 2 },
        { 'w-full': totalAmountOfColumns == 1 }
    );

    return (
        <Link {...item.link[0]} >
            <div
                className={`flex flex-row lg:hover:rounded-lg lg:hover:bg-primary-25 hover:cursor-pointer mt-6 lg:mt-0 ${item.icon ? 'md:py-3 lg:p-3' : 'md:py-2 lg:p-3'}`}
            >
                <div className={`flex flex-col ${widthOfNavMenuItemCn}`}>
                    <div className={'flex flex-row items-center'}>
                        {item.icon &&
                            <div className={'mr-3'}>
                                <Icon
                                    className={'bg-primary-600'}
                                    url={item.icon.source.url}
                                    size={"small"}
                                />
                            </div>
                        }
                        {item.title &&
                            <Text
                                weight={'normal'}
                                size={'md'}
                                className={`${isActive ? 'text-gray-900' : 'text-primary-600'} active:text-primary-600`}
                            >
                                {item.title}
                            </Text>
                        }
                        {item.badge[0] &&
                            <div>
                                <div className={"bg-primary-100 px-3 rounded-full text-sm ml-2"}>
                                    <Text
                                        color={'text-primary-700'}
                                        weight={'normal'}
                                        size={'xs'}
                                    >
                                        {item.badge[0].label}
                                    </Text>
                                </div>
                            </div>
                        }
                    </div>
                    {item.description &&
                        <div className={`${item.icon ? 'ml-9' : 'ml-0'} `}>
                            <Text
                                color={'text-gray-500'}
                                weight={'light'}
                                size={'sm'}
                            >
                                {item.description}

                            </Text>
                        </div>
                    }
                </div>
            </div>
        </Link>

    );
}
