import React, { useEffect, useState } from 'react';
import Logo from "components/base/Logo";
import Navigation from "components/header/mobile/navigation/Navigation";
import { IHeader } from "components/header/Header";
import { useRouter } from "next/router";
import Container from "components/core/Container";

export default function MobileHeader(props: IHeader) {
    const [isNavigationActive, setNavigationActive] = useState(false);
    const [activeSub, setActiveSub] = useState(null);
    const location = useRouter();

    useEffect(() => {
        const root = document.getElementById("__next");
        if (isNavigationActive) {
            root.className = "overflow-hidden h-screen";
        } else {
            root.className = "";
        }
    }, [isNavigationActive]);

    useEffect(() => {
        setNavigationActive(false);
        setActiveSub(false);
    }, [location.asPath]);

    return (
        <div className={`top-0 z-50 fixed w-screen bg-white lg:hidden`}>
            <Container className={`${isNavigationActive && 'border-b border-gray-100 md:border-b-white'}`}>
                <div className={"py-4 md:py-8 flex justify-between"}>
                    <Logo className={'min-w-full mt-0.5'}/>
                    <Navigation
                        isNavigationActive={isNavigationActive}
                        setNavigationActive={setNavigationActive}
                        activeSubItem={activeSub}
                        setActiveSubItem={setActiveSub}
                        {...props}
                    />

                    <div
                        className={'flex gap-x-2 transform ease-in-out duration-100 hover:bg-primary-25 hover:rounded-lg active:rounded-lg active:bg-primary-50 active:outline active:outline-2 active:outline-[#ccccf5]/80 '}
                    >
                        <div className={'m-2'} hidden={props.hideNavigation}>
                            {isNavigationActive ? (
                                <svg
                                    onClick={() => setNavigationActive(!isNavigationActive)}
                                    className={'fill-gray-500 hover:fill-gray-700 active:fill-gray-500'}
                                    width="20"
                                    height="16"
                                    viewBox="0 0 19 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <rect
                                        x="0.261719"
                                        y="18.3086"
                                        width="25"
                                        height="1.5"
                                        rx="0.75"
                                        transform="rotate(-45 0.261719 18.3086)"
                                    />
                                    <rect
                                        x="1.32227"
                                        y="0.630859"
                                        width="25"
                                        height="1.5"
                                        rx="0.75"
                                        transform="rotate(45 1.32227 0.630859)"
                                    />
                                </svg>
                            ) : (
                                <svg
                                    onClick={() => setNavigationActive(!isNavigationActive)}
                                    className={'fill-gray-500 hover:fill-gray-700 active:fill-gray-500'}

                                    width="20"
                                    height="16"
                                    viewBox="0 0 20 16"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 2c-.6 0-1-.5-1-1 0-.6.4-1 1-1h18c.5 0 1 .4 1 1 0 .5-.5 1-1 1H1zm0 7c-.6 0-1-.4-1-1s.4-1 1-1h18c.5 0 1 .4 1 1s-.5 1-1 1H1zm0 7c-.6 0-1-.5-1-1 0-.6.4-1 1-1h18c.5 0 1 .4 1 1 0 .5-.5 1-1 1H1z"
                                    >
                                    </path>
                                </svg>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
}