import React from 'react';
import PreHeading from "components/header/desktop/PreHeading";
import { IHeader } from "components/header/Header";
import Navigation from "components/header/desktop/navigation/Navigation";

export default function DesktopHeader(props: IHeader) {
    const { preHeadingLinks, callToActions, navigation, hideNavigation } = props;
    return (
        <>
            <PreHeading
                links={preHeadingLinks}
            />
            <Navigation
                callToActions={callToActions}
                items={navigation}
                hidden={hideNavigation}
            />
        </>
    );
}


