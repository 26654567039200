import React from "react";
import Button from "components/elements/Button";
import { IFooter } from "components/footer/Footer";
import Logo from "components/base/Logo";
import Paragraph from "components/elements/Paragraph";
import Link from "components/base/Link";

export default function FooterCTA(props: IFooter) {
    return (
        <div className={"flex flex-col w-11/12 pb-12 mr-8"}>
            <Logo className={"w-120"} />
            <p className={"text-gray-500 text-base font-light mt-8"}>
                {props.slogan}
            </p>
            <div className={"flex flex-row mt-5"}>
                {props.button?.map((button) => (
                    <div key={button.id} className="flex items-center">
                        <Link{...button.link[0]}>
                            <Button
                                id={button.id}
                                variant={button.variant}
                                children={button.label}
                                size={"large"}
                                color={button.color}
                                textColor={button.textColor}
                                icon={button.icon}
                                className={"flex pr-2 hover:underline font-normal"}
                            />
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}