import React from "react";
import cn from "classnames";

export type SizeTypes =  "xs" | "sm" | "md" | "lg" | "xl" | "2xl" | "3xl" | "4xl" | "5xl" | "6xl" | "7xl" ;
export type ResponsiveSizeTypes = {
    lg?: SizeTypes,
    md?: SizeTypes,
    sm: SizeTypes
};

export type WeightTypes =
    "thin"
    | "extralight"
    | "light" // 300
    | "normal" // 400
    | "medium" // 500
    | "medium-semibold"
    | "semibold"
    | "bold"
    | "extrabold"
    | "black";

export type HeadingRenderTypes = "h1" | "h2" | "h3" | "h4";

export type TextRenderTypes = "p" | "span" | "label";

export type AllRenderTypes = HeadingRenderTypes | TextRenderTypes;

export type TailwindColors =
    "text-gray-25"
    | "text-gray-50"
    | "text-gray-100"
    | "text-gray-200"
    | "text-gray-300"
    | "text-gray-400"
    | "text-gray-500"
    | "text-gray-600"
    | "text-gray-700"
    | "text-gray-800"
    | "text-gray-900"
    | "text-primary-25"
    | "text-primary-50"
    | "text-primary-100"
    | "text-primary-200"
    | "text-primary-300"
    | "text-primary-400"
    | "text-primary-500"
    | "text-primary-600"
    | "text-primary-700"
    | "text-primary-800"
    | "text-primary-900"
    | "text-white";

type Props = {
    children: any;
    className?: string;
    hidden?: boolean;
    size?: SizeTypes | ResponsiveSizeTypes;
    weight?: WeightTypes;
    color?: string;
    render_as?: TextRenderTypes;
    dangerouslySetInnerHTML?: boolean;
    onClick?: () => void;
};

export default function Text(props: Props) {
    const { size, weight, render_as, color, dangerouslySetInnerHTML, onClick,  } = props;

    const getSize = (s: string) => cn(
        { "text-xs": s == "xs" },
        { "text-sm": s == "sm" },
        { "text-base": s == "md" },
        { "text-lg": s == "lg" },
        { "text-xl": s == "xl" },
        { "text-2xl": s == "2xl" },
        { "text-3xl": s == "3xl" },
        { "text-4xl": s == "4xl" },
        { "text-5xl": s == "5xl" },
        { "text-6xl": s == "6xl" },
        { "text-6xl": s == "7xl" },
    );

    const sizeResponsiveCn = () => {
        // Unreadable please look at this function
        if(typeof size === 'object') {
            return cn(Object.keys(size).map((screenType) => {
                if(screenType == "sm") {
                    return getSize(size[screenType]);
                }
                return screenType + ":" + getSize(size[screenType]);
            }));
        }

        return getSize(size as string);
    };

    const weightCn = cn(
        { "font-thin": weight == "thin" },
        { "font-extralight": weight == "extralight" },
        { "font-light": weight == "light" },
        { "font-normal": weight == "normal" },
        { "font-medium": weight == "medium" },
        { "font-medium-semibold" : weight == "medium-semibold" },
        { "font-semibold": weight == "semibold" },
        { "font-bold": weight == "bold" },
    );

    const classNames = cn(
        sizeResponsiveCn(),
        weightCn,
        color,
        onClick ? "hover:cursor-pointer hover:underline" : "",
        props.className ?? "",
    );

    if (render_as == "p") {
        return (
            <p
                hidden={props.hidden}
                className={classNames}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML ? { __html: props.children } : null}
                onClick={onClick}
            >
                {dangerouslySetInnerHTML ? null : props.children}
            </p>
        );
    }

    if (render_as == "label") {
        return (
            <label
                hidden={props.hidden}
                className={classNames}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML ? { __html: props.children } : null}
                onClick={onClick}
            >
                {dangerouslySetInnerHTML ? null : props.children}
            </label>
        );
    }

    if (render_as == "span") {
        return (
            <span
                hidden={props.hidden}
                className={classNames}
                dangerouslySetInnerHTML={dangerouslySetInnerHTML ? { __html: props.children } : null}
                onClick={onClick}
            >
                {dangerouslySetInnerHTML ? null : props.children}
            </span>
        );
    }

    return null;
}


Text.defaultProps = {
    size: "md",
    weight: "light",
    render_as: "span",
    // color: "text-gray-900",
    dangerouslySetInnerHTML: false
};