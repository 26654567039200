import React from 'react';
import { ILabel } from "components/header/Header";
import Text from "components/typography/Text";

type Props = {
    item: ILabel;
};

export default function LabelItem(props: Props) {
    const { item } = props;

    return (
        <div className={'mb-3 mt-6 lg:ml-3'}>
            <Text
                key={item.id}
                color={'text-primary-600'}
                weight={'normal'}
                size={'sm'}
            >
                {item.label}
            </Text>
        </div>
    );
}
