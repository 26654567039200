import React from 'react';
import { IColumn, IFeaturedItem, INavigationItem } from "components/header/Header";
import { IButton } from 'types/base';
import Icon from "components/elements/Icon";
import Text from "components/typography/Text";
import Column from "components/header/desktop/navigation/Column";
import FeaturedItem from "components/header/desktop/navigation/FeaturedItem";

type Props = {
    activeItem: INavigationItem,
    setActiveItem: (activeItem: any) => void
    callToActions: IButton[]
    setNavigationActive: (isNavActive: boolean) => void;
    columns: IColumn[]
    featuredItem: IFeaturedItem

};

export default function SubNavigation(props: Props) {
    const { activeItem, setActiveItem, columns, featuredItem } = props;

    return (
        <div className={`overflow-y-scroll py-4 w-screen absolute transition-all pb-16`}>
            <div className={'pb-4 border-b border-primary-50 mx-4 md:mx-14'}>
                <button
                    className={"flex items-center text-gray-900"}
                    onClick={() => setActiveItem(null)}
                >
                    <Icon
                        url={"/static/icons/chevron-left.svg"}
                        className={'stroke-current bg-purple-dark mr-2'}
                        size={"extra-small"}
                    />
                    <label>
                        {activeItem.label}
                    </label>
                </button>
            </div>
            <div className={'flex flex-col justify-between'}>
                {columns && columns.map((column) => (
                    <div key={column.id} className={`px-4 md:px-14 ${column.label ? 'pt-4 md:pt-8' : 'pb-4'}`}>
                        {column.label &&
                            <Text
                                color={'text-primary-600'}
                                weight={'normal'}
                                size={'sm'}
                                className={``}
                            >
                                {column.label}
                            </Text>
                        }
                        <div className={''}>
                            <Column items={column.items}/>
                        </div>
                    </div>
                ))}
                {featuredItem && (
                    <FeaturedItem {...featuredItem[0]} />
                )}
            </div>
        </div>
    );
}