import React from 'react';
import { IButton } from "types/base";
import Link from "components/base/Link";
import Button from "components/elements/Button";

type Props = {
    item: IButton;
};

export default function ButtonItem(props: Props) {
    const { item } = props;

    return (
        <Link
            key={item.id}
            {...item.link[0]}
        >
            <div className={'group'}>
                <Button
                    id={item.id}
                    variant={item.variant}
                    size={item.size}
                    color={item.color}
                    icon={item.icon}
                    className={'lg:ml-2 pt-8 group-hover:text-primary-700'}
                >
                    {item.label}
                </Button>
            </div>
        </Link>
    );
}
