import React, { ReactNode } from "react";
import { IText } from "types/base";
import cn from "classnames";

type Props = {
    children: ReactNode;
    className?: string;
    onMouseOver?: any;
    onClick?: any;
};

export default function ListItem(props: Props & IText)
{
    const className = cn(
        props.className ?? ''
    );

    return (
        <li className={className} onMouseOver={props.onMouseOver} onClick={props.onClick}>
            {props.children}
        </li>
    );
}