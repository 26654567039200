import React, { useEffect, useState } from 'react';
import { IHeader, INavigationItem } from "components/header/Header";
import SubNavigation_v2 from "components/header/mobile/navigation/SubNavigation_v2";
import NavigationItem from "components/header/mobile/navigation/NavigationItem";
import Link from "components/base/Link";
import Button from "components/elements/Button";
import ListItem from "components/elements/ListItem";
import LanguageSwitcher from "components/base/LanguageSwitcher";

type Props = {
    isNavigationActive: boolean;
    activeSubItem: INavigationItem;
    setActiveSubItem: (activeSubItem: INavigationItem) => void;
    setNavigationActive: (isNavActive: boolean) => void;
};

export default function Navigation(props: Props & IHeader) {
    const {
        isNavigationActive,
        navigation,
        activeSubItem,
        setActiveSubItem,
        callToActions,
        setNavigationActive
    } = props;

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    return (
        <div
            className={`absolute overflow-auto w-screen top-full bg-white inset-0 transition-transform ${isNavigationActive ? 'h-screen' : 'h-px'} ${scrollPosition > 0 ? 'shadow-sm' : ''} `}
        >
            <div className={"h-full relative flex"}>
                <div
                    className={`h-full w-screen sm:mx-auto sm:container transition-transform px-4 ${activeSubItem ? 'transform -translate-x-full opacity-10' : ''}`}
                >
                    <ul>
                        {navigation && navigation.map((item) => {
                                return (
                                    <ListItem
                                        key={`mobile-${item.id}`}
                                        onClick={item.columns.length == 0 ? () => setNavigationActive(!isNavigationActive) : null}
                                    >
                                        <NavigationItem
                                            {...item}
                                            onClick={() => setActiveSubItem(item)}
                                            hasChildren={item.columns?.length > 0}
                                        />
                                    </ListItem>
                                );
                            }
                        )}
                    </ul>
                    {callToActions && (
                        <ul className={activeSubItem ? 'hidden' : ''}>
                            {callToActions.map((button) => (
                                <ListItem key={button.id} className={"my-4"}>
                                    <Link{...button.link[0]}>
                                        <Button
                                            id={button.id}
                                            variant={button.variant}
                                            size={button.size}
                                            color={button.color}
                                            textColor={button.textColor}
                                            icon={button.icon}
                                            className={"w-full justify-center"}
                                        >
                                            {button.label}
                                        </Button>
                                    </Link>
                                </ListItem>
                            ))}
                        </ul>
                    )}
                    <LanguageSwitcher/>
                </div>

                {activeSubItem && (
                    <SubNavigation_v2
                        activeItem={activeSubItem}
                        setNavigationActive={props.setNavigationActive}
                        setActiveItem={setActiveSubItem}
                        callToActions={callToActions}
                        columns={activeSubItem.columns}
                        featuredItem={activeSubItem.featuredItem}
                    />
                )}

            </div>
        </div>
    );
}