import { EnumType } from "json-to-graphql-query";

export const PageSlug = {
    page: {
        __typename: true,
        __on: [
            {
                __typeName: "ResourcesPageRecord",
                slug: true
            },
            {
                __typeName: "CustomerStoryRecord",
                slug: true
            },
            {
                __typeName: "BalanceCheckerRecord",
                slug: true
            },
            {
                __typeName: "PricingRecord",
                slug: true
            },
            {
                __typeName: "BlogPostRecord",
                slug: true
            },
            {
                __typeName: "BlogRecord",
                slug: true
            },
            {
                __typeName: "PageRecord",
                slug: true,
            },
            {
                __typeName: "IntegrationPageRecord",
                slug: true
            },
            {
                __typeName: "PartnersPageRecord",
                slug: true
            },
            {
                __typeName: "EventsPageRecord",
                slug: true
            },
            {
                __typeName: "CustomerRecord",
                slug: true
            },
            {
                __typeName: "LegalSectionRecord",
                slug: true
            },
            {
                __typeName: "FormPageRecord",
                slug: true
            },
            {
                __typeName: "IndustryRecord",
                slug: true
            },
            {
                __typeName: "GlossaryPageRecord",
                slug: true
            },
            {
                __typeName: "ResourcesPageRecord",
                slug: true
            },
            {
                __typeName: "AgencyRecord",
                slug: true
            }
        ],
    }
};

export const BaseLink = {
    isExternalLink: true,
    externalUrl: true,
    ...PageSlug
};


export const Icon = {
    name: true,
    source: {
        url: true
    }
};

export const Button = {
    id: true,
    label: true,
    size: true,
    variant: true,
    icon: Icon,
    link: BaseLink,
};

export const Image = {
    id: true,
    customData: true,
    responsiveImage: {
        __args: {
            imgixParams: {
                fm: new EnumType("webp")
            }
        },
        srcSet: true,
        webpSrcSet: true,
        sizes: true,
        src: true,
        width: true,
        height: true,
        aspectRatio: true,
        title: true,
        alt: true,
    },
};

export const JsonFile = {
    id: true,
    url: true,
};

export const Video = {
    url: true,
    height: true,
    width: true,
    title: true,
    provider: true,
    providerUid: true,
    thumbnailUrl: true,
};

export const Author = {
    id: true,
    name: true,
    company: true,
    function: true,
    image: Image
};

export const Quote = {
    id: true,
    quote: true,
    author: Author,
    logo: Image,
    image: Image
};

export const Usp = {
    id: true,
    container: true,
    title: true,
    iconBoxColor: true,
    description: {
        value: true
    },
    link: BaseLink,
    icon: Icon
};

export const SeoMeta = {
    seo: {
        __aliasFor: '_seoMetaTags',
        attributes: true,
        content: true,
        tag: true
    }
};

export const FaqItem = {
    id: true,
    question: true,
    answer: {
        value: true
    }
};

