import { Button, FaqItem, Image, JsonFile, Usp, Video } from "queries/Base";

export const ButtonListRecord = [
    {
        __typeName: "ButtonListRecord",
        __typename: true,
        id: true,
        buttons: Button,
    }
];

export const ButtonRecord = [
    {
        __typeName: "ButtonRecord",
        __typename: true,
        ...Button,
    }
];

export const UspListRecord = [
    {
        __typeName: "UspListRecord",
        __typename: true,
        id: true,
        theme: true,
        hasTitle: true,
        noSpaceTop: true,
        lessSpaceTop: true,
        lessSpaceBottom: true,
        title: true,
        description: {
            value: true,
        },
        preHeading: true,
        alignment: true,
        usps: Usp,
    }
];

export const FormRecord = [
    {
        __typeName: "FormRecord",
        __typename: true,
        id: true,
        placeholder: true,
        button: Button,
    }
];

export const CompaniesListRecord = [
    {
        __typeName: "CompaniesListRecord",
        __typename: true,
        id: true,
        title: true,
        titleSize: true,
        lessSpaceTop: true,
        lessSpaceBottom: true,
        theme: true,
        logos: Image,
    }
];

export const QuoteRecord = [
    {
        __typeName: "QuoteRecord",
        __typename: true,
        id: true,
        lessSpaceTop: true,
        lessSpaceBottom: true,
        theme: true,
        logoImage: Image,
        quoteText: true,
        quoteSource: true,
        quoteSourceImage: Image,
        quoteSourceDescription: true,
        container: true,
    }
];

export const CaseQuoteRecord = [
    {
        __typeName: "CaseQuoteRecord",
        __typename: true,
        id: true,
        theme: true,
        lessSpaceTop: true,
        lessSpaceBottom: true,
        rightTextAlign: true,
        logoImage: Image,
        quoteSourceDescription: true,
        quoteText: true,
        quoteSource: true,
        quoteSourceImage: Image,
    }
];

export const MediaRecord = [
    {
        __typeName: "MediaObjectRecord",
        __typename: true,
        id: true,
        image: Image,
        video: Video,
        wistiaVideo: true
    }
];

export const CaseBlockRecord = [
    {
        __typeName: "CaseBlockRecord",
        __typename: true,
        id: true,
        cases: {
            id: true,
            title: true,
            image: Image,
            button: Button,
        },
    }
];

export const CaseCarrouselRecord = [
    {
        __typeName: "CaseCarrouselRecord",
        __typename: true,
        id: true,
        title: true,
        theme: true,
        description: true,
        cases: {
            title: true,
            image: Image,
            button: Button,
        },
    }
];

export const RoiListRecord = [
    {
        __typeName: "RoiListRecord",
        __typename: true,
        id: true,
        alignment: true,
        theme: true,
        size: true,
        roi: {
            id: true,
            value: true,
            symbol: true,
            description: true,
        },
        container: true,
        hasTitle: true,
        title: true,
        description: {
            value: true,
        },
    }
];

export const RoiRecord = [
    {
        __typeName: "RoiRecord",
        __typename: true,
        id: true,
        value: true,
        symbol: true,
        description: true,
    }
];

export const SimpleTextRecord = [
    {
        __typeName: "SimpleTextRecord",
        __typename: true,
        id: true,
        referenceId: true,
        text: {
            value: true,
            blocks: {
                __typename: true,
                __on: [
                    ...MediaRecord,
                ]
            }
        },
    }
];

export const TableRecord = [
    {
        __typeName: "TableRecord",
        __typename: true,
        id: true,
        table: true
    }
];

export const PlanOnboardingRecord = [
    {
        __typeName: "PlanOnboardingRecord",
        __typename: true,
        id: true,
        theme: true,
        align: true,
        preHeading: true,
        meetingsUrl: true,
        title: true,
        description: {
            value: true
        },
        button: Button,
    }
];


export const HeroRecord = [
    {
        __typeName: "HeroRecord",
        __typename: true,
        id: true,
        preHeading: true,
        title: true,
        description: {
            value: true
        },
        image: Image,
        callToAction: {
            __on: ButtonRecord
        }
    }
];
export const CaseHero = [
    {
        __typeName: "CaseHeroRecord",
        __typename: true,
        id: true,
        preHeading: true,
        title: true,
        description: {
            value: true
        },
        image: Image,
        callToAction: {
            __on: ButtonRecord
        },
        wistiaUrl: true,
    }
];

// please leave this for future development purposes regarding lottie component
export const LottieRecord = [
    {
        __typeName: "LottieRecord",
        __typename: true,
        id: true,
        animationFile: JsonFile
    }
];

export const TextRecord = [
    {
        __typeName: "TextRecord",
        __typename: true,
        id: true,
        isHero: true,
        largeText: true,
        lessSpaceTop: true,
        lessSpaceBottom: true,
        theme: true,
        align: true,
        preHeading: true,
        title: true,
        description: {
            value: true,
        },
        image: Image,
        animation: JsonFile,

        callToAction: {
            __on: [
                ...ButtonRecord,
                ...FormRecord,
            ]
        },

        // roi: {
        //     __on: RoiRecord
        // }

    }
];

export const ImageSliderItem = {
    __typeName: "ImageSliderItemRecord",
    __typename: true,
    heading: true,
    description: true,
    image: Image
};

export const ImageSliderRecord = [
    {
        __typeName: "ImageSliderRecord",
        __typename: true,
        id: true,
        theme: true,
        placeOfNavigation: true,
        lessSpaceTop: true,
        lessSpaceBottom: true,
        hasTitle: true,
        title: true,
        preHeading: true,
        description: {
            value: true,
        },
        alignment: true,
        imageSliderItem: ImageSliderItem
    }
];


export const Cta2Record = [
    {
        __typeName: "Cta2Record",
        __typename: true,
        id: true,
        cta: {
            id: true,
            title: true,
            theme: true,
            ctaForm: {
                __on: [
                    ...ButtonListRecord,
                    ...FormRecord,
                ]
            },
            image: Image,
            alignment: true,
            container: true,
            description: {
                value: true
            },
            hasAwards: true,

        },
    }
];

export const ctaModel = {
    id: true,
    theme: true,
    title: true,
    ctaForm: {
        __on: [
            ...ButtonListRecord,
            ...FormRecord,
        ]
    },
    image: Image,
    alignment: true,
    container: true,
    description: {
        value: true
    },
    lessSpaceTop: true,
    lessSpaceBottom: true,
    hasAwards: true,
};

export const HubspotFormRecord = [
    {
        __typeName: "HubspotFormRecord",
        __typename: true,
        id: true,
        hubspotFormId: true,
        disclaimer: true,
        media: MediaRecord
    }
];

export const FaqRecord = [
    {
        __typeName: "FaqRecord",
        __typename: true,
        id: true,
        theme: true,
        lessSpaceTop: true,
        lessSpaceBottom: true,
        preHeading: true,
        title: true,
        description: {
            value: true,
        },
        faqItems: FaqItem,
    }
];

export const ImageGalleryRecord = [
    {
        __typeName: "ImageGalleryRecord",
        __typename: true,
        id: true,
        theme: true,
        title: true,
        lessSpaceTop: true,
        lessSpaceBottom: true,
        images: Image,
    }
];




