import React from 'react';
import { ILabel, INavMenuItem } from "components/header/Header";
import { IButton } from "types/base";
import LabelRecord from "components/header/desktop/navigation/LabelItem";
import LabelItem from "components/header/desktop/navigation/LabelItem";
import ButtonItem from "components/header/desktop/navigation/ButtonItem";
import NavMenuItem from "components/header/desktop/navigation/NavMenuItem";


type Props = {
    items:  (ILabel|IButton|INavMenuItem)[];
    isActive? : boolean;
    totalAmountOfColumns? : number;
};

export default function Column(props: Props) {
    const { items, totalAmountOfColumns } = props;


    return (
        <div className={'flex flex-col lg:gap-y-2 lg:mt-3'}>
            {items && items.map((item) => {
                return (
                    <div key={item.id} className={''}>
                        {item.__typename === 'LabelRecord' && <LabelItem item={item as ILabel}/>}
                        {item.__typename === 'ButtonRecord' && <ButtonItem item={item as IButton}/>}
                        {item.__typename === 'NavMenuItemRecord' && <NavMenuItem item={item as INavMenuItem} totalAmountOfColumns={totalAmountOfColumns}/>}
                    </div>
                );
            })}
        </div>
    );
}