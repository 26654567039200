import React from "react";
import Paragraph from "components/elements/Paragraph";
import Button from "components/elements/Button";
import useTrans from "components/hooks/useTrans";
import cn from "classnames";

type Props = {
    isFlagActive: boolean;
    setFlagActive: (isActive: boolean) => void;
    setModalActive: (isActive: boolean) => void;
    save: any;
};
export default function Flag(props: Props) {
    const { setFlagActive, setModalActive, save, isFlagActive } = props;
    const { t } = useTrans();

    const containerCn = cn(
        "fixed p-3 transition transform z-40 bg-purple-dark lg:w-max bottom-0",
        { "-translate-x-full" : !isFlagActive },
        "lg:p-4 lg:max-w-sm lg:bottom-10 lg:rounded-r-2xl lg:opacity-90"
    );

    return (
        <div className={containerCn}>
            <div className={'pb-5'}>
                <Paragraph className={'text-white font-light text-sm '}>
                    {t(`cookieFlagHeader`)}
                </Paragraph>
            </div>
            <div className={'flex flex-row gap-2'}>
                <Button
                    id={"cookie-consent-flag__accept"}
                    size={'medium'}
                    color={'white'}
                    className={'bg-green-500  hover:text-gray-900'}
                    onClick={() => {
                        save();
                        setFlagActive(false);
                    }}
                >
                    {t('accept')}
                </Button>
                <Button
                    id={"cookie-consent-flag__manage-cookies"}
                    size={'medium'}
                    color={'white'}
                    className={'bg-gray-button text-white mix-blend-lighten h-12 sm:h-full'}
                    onClick={() => {
                        setModalActive(true);
                        setFlagActive(false);
                    }}
                >
                    {t('manageCookies')}
                </Button>
            </div>
        </div>
    );
}