import React from "react";
import Link from "components/base/Link";
import Icon from "components/elements/Icon";
import cn from "classnames";
import { IFooterItem } from "components/footer/Footer";

type Props = {
    items: IFooterItem[];
};

export default function FooterBar(props: Props) {
    const { items } = props;

    const socials = [
        {
            to: "https://www.instagram.com/life_at_piggy/?next=%2F",
            url: "/static/icons/instagram.svg",
        },
        {
            to: "https://www.facebook.com/piggyloyalty/",
            url: "/static/icons/facebook.svg",
        },
        {
            to: "https://nl.linkedin.com/company/piggy/",
            url: "/static/icons/linkedin.svg",
        },
    ];

    const containerCn = cn(
        "bg-gray-50 py-12",
        "md:flex-row",
    );

    const innerContainerCn = cn(
        "flex mx-auto container justify-start",
        "lg:justify-end"
    );

    const contentContainerCn = cn(
        "flex flex-col w-705/1000 justify-between flex-wrap lg:pr-1",
        "sm:flex-row",
    );

    const linkCn = cn(
        "text-purple-tint-dark cursor-pointer font-light",
        "hover:text-purple hover:underline mb-2",
        "sm:mb-0 sm:mr-16 last:mr-0"
    );

    const linksContainerCn = cn(
        "flex flex-col text-base",
        "md:flex-wrap",
        "sm:flex-row sm:content-center"
    );

    const iconCn = cn(
        "bg-purple-tint-dark cursor-pointer w-5 h-5",
        "hover:bg-purple",
    );

    const iconContainerCn = cn(
        "flex space-x-4 content-center",
        "mt-4",
        "lg:mt-0"
    );

    return (
        <div className={containerCn}>
            <div className={innerContainerCn}>
                <div className={contentContainerCn}>
                    <ul className={linksContainerCn}>
                        {items.map(item => (
                            <li key={item.id} className={linkCn}>
                                <Link {...item.link[0]}>
                                    {item.label}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <div className={iconContainerCn}>
                        {socials.map((social) => (
                            <Link key={social.url} to={social.to} isExternalLink={true}>
                                <Icon
                                    url={social.url}
                                    className={iconCn}
                                />
                            </Link>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}