import React, { ReactNode } from "react";
import cn from "classnames";
import { adjustAlignment, fontWeight, textColorHeading } from "classes";
import { ITextAlignment, ITextColor, ITextWeight, IThemes } from "types/base";

export type IHeadings = "h1" | "h2" | "h3" | "h4" | "h5" | "h2-no-margin" | "h3-no-margin" | "h4-no-margin"
                        | "h1-large" | "h2-consistent" | "h2-cta" ;

type Props = {
    children: ReactNode;
    renderAs: IHeadings
    id?: string;
    theme?: IThemes;
    color?: ITextColor;
    align?: ITextAlignment;
    fontWeight?: ITextWeight;
    className?: string;
};

function Heading(props: Props) {
    const { renderAs } = props;
    const id = props.id !== undefined ? props.id : null;

    const sizesCn = cn(
        { "text-4xl lg:text-6xl tracking-tight mb-4 lg:mb-6": renderAs == "h1-large" },
        { "text-4xl sm:text-5xl tracking-tight mb-4 lg:mb-6": renderAs == "h1" },
        { "text-3xl font-medium leading-9.5 mb-4 xl:text-4xl xl:leading-11 xl:mb-5" : renderAs == "h2" },
        { "text-3xl lg:text-4xl font-medium" : renderAs == "h2-cta" },
        { "text-3xl font-medium" : renderAs == "h2-consistent" },
        { "text-2xl lg:text-4xl mb-3": renderAs == "h3" },
        { "text-lg md:text-xl mb-1 md:mb-2": renderAs == "h4" },
        { "text-3xl sm:text-5xl": renderAs == "h2-no-margin" },
        { "text-2xl lg:text-4xl": renderAs == "h3-no-margin" },
        { "text-md lg:text-lg" : renderAs == "h4-no-margin" },
        { "text-xl" : renderAs == "h5" }
    );

    const className = cn(
        sizesCn,
        textColorHeading(props.theme),
        adjustAlignment(props.align),
        fontWeight(props.fontWeight),
        props.className ? props.className : ""
    );

    switch (renderAs) {
        // Use this for page title's (one H1 allowed per page)
        case "h1-large":
            return (
                <h1 id={id} className={className}>
                    {props.children}
                </h1>
            );
        case "h1":
            return (
                <h1 id={id} className={className}>
                    {props.children}
                </h1>
            );
        // Use this for section headings
        case "h2":
            return (
                <h2 id={id} className={className}>
                    {props.children}
                </h2>
            );

        // Use this for cta's
        case "h2-cta":
            return (
                <h2 id={id} className={className}>
                    {props.children}
                </h2>
            );

        // Use this when font-size stays consistent across different screen sizes
        case "h2-consistent":
            return (
                <h2 id={id} className={className}>
                    {props.children}
                </h2>
            );

        // Use this for sub sections
        case "h3":
            return (
                <h3 id={id} className={className}>
                    {props.children}
                </h3>
            );
        case "h4":
            return (
                <h4 id={id} className={className}>
                    {props.children}
                </h4>
            );
        case "h2-no-margin":
            return (
                <h2 id={id} className={className}>
                    {props.children}
                </h2>
            );
        // Use this for sub sections
        case "h3-no-margin":
            return (
                <h3 id={id} className={className}>
                    {props.children}
                </h3>
            );
        case "h4-no-margin":
            return (
                <h4 id={id} className={className}>
                    {props.children}
                </h4>
            );
        case "h5":
            return (
                <h5 id={id} className={className}>
                    {props.children}
                </h5>
            );
    }
}

Heading.defaultProps = {
    color: "purple-dark",
    alignment: "center",
    theme: "default",
    fontWeight: "semi-bold",
};

export default Heading;