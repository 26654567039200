import React from 'react';
import { ILink } from "types/base";
import Link from "components/base/Link";
import LanguageSwitcher from "components/base/LanguageSwitcher";
import Container from 'components/core/Container';

type Props = {
    links: ILink[];
};

export default function PreHeading(props: Props) {
    const { links } = props;
    return (
        <div className={"absolute w-full z-50"}>
            <Container className="hidden lg:block">
                <div className={"pt-3.5 pb-0.5 flex justify-end"}>
                    {links && links.map((link) =>
                        <Link key={link.id} {...link}>
                            <span
                                className={"px-2 py-4 text-sm transition-all text-purple-tint-dark hover:text-purple hover:underline"}
                            >{link.label}</span>
                        </Link>
                    )}
                    <LanguageSwitcher size={"medium"}/>
                </div>
            </Container>
        </div>
    );
}