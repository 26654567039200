import React from 'react';
import { IButton, IImage } from "types/base";
import { Image } from "react-datocms";
import Paragraph from "components/elements/Paragraph";
import Link from "components/base/Link";
import Button from "components/elements/Button";


type Props = {
    id?: string,
    image?: IImage,
    title?: string,
    description?: any,
    link?: IButton[];
};

export default function FeaturedItem(props: Props) {
    const { image, title, description, link } = props;

    return (
        <div className={'flex flex-col bg-gray-50 bg-right mt-6 md:mt-12 lg:hidden xl:inline-flex xl:mt-0 px-4 md:px-14 xl:px-0 xl:pl-10 pb-32 xl:pb-10 pt-6 xl:pt-10 xl:w-1/4 2xl:w-1/3 z-20'}>
            <div className={"max-w-[320px]"}>
                <div className={'mb-6'}>
                    {image && (
                        <Image data={image.responsiveImage} pictureClassName={'object-contain rounded-lg h-full w-full'} explicitWidth={true}/>
                    )}
                </div>
                <div className={'mb-1'}>
                    {title}
                </div>
                {description && (
                    <div className={`mb-3`}>
                        <Paragraph
                            renderAs={"structuredText"}
                            className={'text-gray-500 text-sm'}
                            fontWeight={"light"}
                        >
                            {description}
                        </Paragraph>
                    </div>
                )}
                {link && link.map((el) => (
                    <Link {...el.link[0]} key={el.id}>
                        <Button
                            id={el.id}
                            variant={el.variant}
                            size={el.size}
                            color={el.color}
                            icon={el.icon}
                            className={'text-sm hover:text-primary-700'}
                        >
                            {el.label}
                        </Button>
                    </Link>
                ))}
            </div>
        </div>
    );
}