import React, { ReactNode } from 'react';
import cn from "classnames";

type Props = {
    className?: string;
    children: ReactNode;
};

export default function Main(props: Props) {

    const classes = cn(
        "mt-8 lg:mt-0 lg:pt-24",
        props.className && props.className
    );

    return (
        <main className={classes}>
            {props.children}
        </main>
    );
}