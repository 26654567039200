import React, { ReactNode } from 'react';

type Props = {
    label: string|ReactNode;
    value: string | number;
    description?: string,
    disabled?: boolean;
    selected?: boolean;
};

export default function Option(props: Props) {
    return (
        <option
            value={props.value}
            disabled={props.disabled}
            selected={props.selected}
        >
            {props.label}
        </option>
    );
}