import React from 'react';
import { INavigationItem } from "components/header/Header";
import Link from "components/base/Link";
import ListItem from "components/elements/ListItem";
import Icon from "components/elements/Icon";
import cn from "classnames";
import useRouter from "components/hooks/useRouter";

type Props = {
    isActive: boolean;
    onMouseOver?: () => void;
    onClick?: () => void;
    className?: string;
    activeItem?: boolean;
};

export default function NavigationItem(props: Props & INavigationItem) {
    const { isActive, isExternalLink, externalUrl, page, label, className, onClick } = props;
    const onMouseOver = props.onMouseOver !== undefined ? props.onMouseOver : null;
    const isLink = (isExternalLink || externalUrl || page);

    return (
        <ListItem
            className={className}
            onMouseOver={onMouseOver}
            onClick={onClick}
        >
            {isLink ? (
                <div className={'flex items-center gap-0.2 py-1 px-4 rounded-full cursor-pointer hover:bg-primary-25 '}>
                    <label className={`${isActive ? 'text-primary-500' : 'text-gray-500'} cursor-pointer`}>
                        <Link
                            isExternalLink={isExternalLink}
                            externalUrl={externalUrl}
                            page={page}
                            className={"navigation__link"}
                        >
                            {label}
                        </Link>
                    </label>
                </div>
            ) : (
                <div className={"flex items-center py-1 px-4 rounded-full cursor-pointer hover:bg-primary-25"}>
                    <label className={`${isActive ? 'text-primary-500' : 'text-gray-500'} cursor-pointer`}>
                        {label}
                    </label>
                    <Icon
                        url={'/static/icons/dropdown-icon.svg'}
                        className={`w-2.5 h-2.5 ml-2 ${isActive ? 'bg-primary-500 rotate-180 transition-all ease-out-in duration-200' : 'bg-gray-500 transition-all ease-out-in duration-200'} cursor-pointer`}
                    />
                </div>
            )}
        </ListItem>
    );
}

