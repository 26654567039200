import React, { ReactNode } from "react";
import cn from "classnames";
import { parseUrl } from "helpers";

type Props = {
    children: ReactNode;
    to?: string;
    className?: string;
    openNewTab?: boolean;
};

export default function Anchor(props: Props)
{
    const className = cn(props.className && props.className);
    const openNewTab = props.openNewTab === undefined ? false : props.openNewTab;
    const to = props.to ? parseUrl(props.to) : "#";

    return (
        <a href={to} className={className} target={openNewTab ? "_blank" : null}>
            {props.children}
        </a>
    );
}