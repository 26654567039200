import { useEffect, useState } from "react";

export default function useChatWidget() {

    useEffect(() => {
        refresh();

        return () => {
            refresh();
        };
    }, []);

    function refresh() {
        if (window.HubSpotConversations) {
            window.HubSpotConversations.widget.refresh();
        } else {
            window.hsConversationsOnReady = [
                () => {
                    window.HubSpotConversations.widget.refresh();
                },
            ];
        }
    }

    function open() {
        if (window.HubSpotConversations) {
            window.HubSpotConversations.widget.open();
        } else {
            window.hsConversationsOnReady = [
                () => {
                    window.HubSpotConversations.widget.open();
                },
            ];
        }
    }

    return {
        refresh,
        open
    };
}