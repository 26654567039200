import { ITextAlignment, ITextWeight, IThemes } from "types/base";
import cn from "classnames";

export const adjustAlignment = (alignment: ITextAlignment) => cn(
    { "text-center": alignment == "center" },
    { "text-right": alignment == "right" },
    { "text-left": alignment == "left" },
);

export const fontWeight = (weight: ITextWeight) => cn(
    { "font-light": weight == "light" },
    { "font-normal": weight == "medium" },
    { "font-medium": weight == "semi-bold" },
);

export const backgroundColor = (theme: IThemes) => cn(
    { "bg-white -mt-0.5" : theme == "white"  },
    { "bg-white -mt-0.5" : theme == "default" },
    { 'bg-primary-25 -mt-0.5': theme == "primary" },
    { 'bg-primary-25 -mt-0.5': theme == "gray" },
    { 'bg-primary-900 -mt-0.5': theme == "dark" },
);

export const backgroundColorContainer = (theme: IThemes) => cn(
    { "bg-white -mt-0.5" : theme == "white" },
    { "bg-white -mt-0.5" : theme == "default" },
    { 'bg-primary-50 -mt-0.5': theme == "primary" },
    { 'bg-primary-50 -mt-0.5': theme == "gray" },
    { 'bg-primary-900 -mt-0.5': theme == "dark" },
);

export const textColorHeading = (theme: IThemes) => cn(
    { 'text-gray-900': theme == "default" }, //needs to be white
    { 'text-gray-900' : theme == "white" },
    { 'text-gray-900': theme == "gray" }, // needs to be primary
    { 'text-gray-900': theme == "primary" },
    { 'text-white': theme == "dark" },
);

export const textColorPreHeading = (theme: IThemes) => cn(
    { "text-primary-600" : theme == "default" },
    { "text-primary-600" : theme == "white" },

    { "text-primary-600" : theme == "gray" },
    { "text-primary-600" : theme == "primary" },

    { "text-gray-200" : theme == "dark" }
);

export const textColorParagraph = (theme: IThemes) => cn(
    { "text-gray-500": theme == "default" },
    { "text-gray-500": theme == "white" },

    { "text-gray-500": theme == "gray" },
    { "text-gray-500": theme == "primary" },

    { "text-gray-200": theme == "dark" }
);

export const noSpaceTopCn = cn(
    "pt-0 md:pt-0 lg:pt-0"
);

export const lessSpaceTopCn = cn(
    "pt-8 md:pt-10 lg:pt-12"
);

export const lessSpaceBottomCn = cn(
    "pb-8 md:pb-10 lg:pb-12"
);

export const normalSpaceTopCn = cn(
    "pt-16 md:pt-20 lg:pt-24"
);

export const normalSpaceBottomCn = cn(
    "pb-16 md:pb-20 lg:pb-24"
);