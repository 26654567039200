import React from "react";
import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import Main from "components/core/Main";
import CookieConsent from "components/cookieConsent/CookieConsent";
import { withPreview } from "components/hooks/usePreview";
import useChatWidget from "components/hooks/useChatWidget";
import { renderMetaTags } from "react-datocms";
import Head from "next/head";

export const withAppLayout = (Component: any) => (props: any) => {
    const chat = useChatWidget();
    const isSubscribable = props.data.token;
    const isPreview = props.data.preview;

    const showHeader = props.hideHeader === undefined;
    const showFooter = props.hideFooter === undefined;

    const ComponentToRender = isPreview ? withPreview(Component) : Component;

    const data = isSubscribable && !isPreview ? props.data.initialData : props.data;

    return (
        <>
            <Head>
                {props.seo && renderMetaTags(props.seo)}
            </Head>

            <CookieConsent />

            {showHeader && <Header {...props.appData} hideNavigation={data?.page?.hideNavigation} />}

            <Main>
                <ComponentToRender {...data} />
            </Main>

            {showFooter &&  <Footer {...props.appData} hidden={props.hideFooter} />}
        </>
    );
};