import { BaseLink, Button, Icon, Image } from "queries/Base";
import { EnumType } from "json-to-graphql-query";
import {  ButtonRecord } from "queries/Blocks";

export const HeaderQuery = (locale: string) => ({
    header: {
        __args: {
            locale: new EnumType(locale)
        },
        preHeadingLinks: NavigationItemRecord,
        navigation: NavigationItemRecord,
        callToActions: Button
    }
});

export const LabelRecord = {
    __typeName: "LabelRecord",
    __typename: true,
    id: true,
    label: true
};

export const BadgeRecord = {
    __typeName: "BadgeRecord",
    __typename: true,
    id: true,
    label: true
};

export const NavMenuItemRecord = {
    __typeName: "NavMenuItemRecord",
    __typename: true,
    id: true,
    title: true,
    description: true,
    icon: Icon,
    badge: BadgeRecord,
    link: BaseLink,
};

export const ColumnRecord = {
    __typeName: "ColumnRecord",
    __typename: true,
    id: true,
    label: true,
    items: {
        __on: [
            LabelRecord,
            ...ButtonRecord,
            NavMenuItemRecord,
        ]
    }
};

export const FeaturedItemRecord = {
    __typeName: "FeaturedItemRecord",
    __typename: true,
    id: true,
    image: Image,
    title: true,
    description: {
        value: true,
    },
    link: ButtonRecord,
};

export const NavigationItemRecord = {
    __typeName: "NavigationItemRecord",
    __typename: true,
    id: true,
    label: true,
    ...BaseLink,
    columns: ColumnRecord,
    featuredItem: FeaturedItemRecord
};