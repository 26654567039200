import React, { CSSProperties } from "react";
import cn from "classnames";
import { ITextSize, IThemes } from "types/base";

type Props = {
    url: string;
    size?: ITextSize;
    theme?: IThemes;
    attr?: "default" | "button";
    className?: string;
    onClick?: any;
    disabled?: boolean;
    style?: CSSProperties;
};

export const localIcon = (name: string, className = "") => {
    return {
        name: name,
        source: {
            url: `/static/icons/${name}.svg`
        },
        className: className
    };
};

function Icon(props: Props) {
    const { size, url, style, disabled, onClick } = props;

    const sizeCn = cn(
        { "h-3 w-4 lg:h-4 lg:w-4" : size == "extra-small" },
        { "h-6 w-6" : size == "small" },
        { "h-12 w-12" : size == "medium" }
    );

    const className = cn(
        sizeCn,
        props.className
    );

    if (props.attr == 'button') {
        return (
            <button
                type={"button"}
                disabled={disabled}
                onClick={onClick}
                className={`ga__button ${className}`}
                style={{
                    ...style,
                    mask: `url(${url}) no-repeat center center`,
                    WebkitMask: `url(${url}) no-repeat center center`,
                }}
            />
        );
    }

    return (
        <div
            onClick={onClick}
            className={className}
            style={{
                ...style,
                mask: `url(${url}) no-repeat center center`,
                WebkitMask: `url(${url}) no-repeat center center`,
            }}
        />
    );
}

Icon.defaultProps = {
    attr: "default",
};

export default Icon;