import React from 'react';
import cn from "classnames";

type Props = {
    onChange: any;
    checked: boolean;
    disabled?: boolean;
    className?: string;
};

export default function Switch(props: Props) {
    const { onChange, checked, disabled, className } = props;

    const disabledCn = cn(
        { "cursor-not-allowed opacity-40" : disabled },
        { "cursor-pointer" : !disabled }
    );

    const colorCn = cn(
        checked ? 'bg-green' : 'bg-purple-tint',
    );

    return (
        <div className={`flex items-center ${className} ${disabledCn}`} onClick={onChange}>
            <div className="relative">
                <input type="checkbox" className="sr-only" checked={checked} onChange={onChange} disabled={disabled}/>
                <div className={`block w-11 h-6 rounded-full ${colorCn} transition duration-300`}/>
                <div className={`dot absolute left-0.5 top-0.5 w-5 h-5 bg-white rounded-full transition ${checked ? 'transform translate-x-full' : ''}`}/>
            </div>
        </div>
    );
}