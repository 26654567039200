import { ITextColor } from "types/base";
import cn from "classnames";

export const camelCaseToDash = (value: string) => {
    return value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
};

export const parseUrl = (url: string) => {
    if (new RegExp(["http://", "https://"].join("|")).test(url)) {
        return url;
    }

    return `https://${url}`;
};

export const color = (color: ITextColor) => cn(
    { 'text-purple': color == "purple" },
    { 'text-purple-tint-dark': color == "purple-tint" },
    { 'text-purple-dark': color == "purple-dark" },
    { 'text-blue': color == "blue" },
    { 'text-blue-tint': color == "blue-tint" },
);

export const formatToDashedString = (value: string) => {
    return value?.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
};

export const formatNumberToCurrency = (value: number | string, locale: string, currency = "EUR", ceil = true) => {

    const numberFormat = new Intl.NumberFormat(locale, { style: "currency", currency: currency });

    const parts = numberFormat.formatToParts(ceil ? Math.ceil(Number(value)) : Number(value));

    const currencyIndex = parts.findIndex(p => p.type == "currency");

    parts.splice(currencyIndex, 1);

    const skipFraction = parts.find((part) => part.type == "fraction" && part.value == "00");

    let formatted = "";
    parts.forEach((part) => {
        if (part.type == "fraction" && part.value == "00") {
            return;
        }

        if (part.type == "decimal" && skipFraction) {
            return;
        }

        if (part.type == "literal") {
            return;
        }

        formatted = formatted + part.value;
    });

    return formatted;
};

export const formatNumberToLocale = (value: number | string, locale: string, currency = "EUR") => {
    return Number(value).toLocaleString(locale);
};

export const formatNumber = (value: number | string, currency = "EUR") => {
    return Number(value);
};

export const getCurrencySign = (locale: string) => {
    return locale == "en" ? "$" : "€";
};

export const getCurrencySignFromCurrency = (currency: string) => {
    return currency == "EUR" ? "€" : "$";
};

export const abbreviation = (value: string) => {
    if (!value) {
        return "AB";
    }

    if (value.includes(" ")) {
        return value.split(" ", 2).map((i) => i.charAt(0));
    }

    if (value.length === 3) {
        return value.toUpperCase();
    }

    return value.slice(0, 2).toUpperCase();
};