import { EnumType } from "json-to-graphql-query";
import { BaseLink, Button } from "queries/Base";

export const FooterItem = {
    id: true,
    label: true,
    link: BaseLink,
};

export const FooterColumn = {
    label: true,
    items: FooterItem,
};

export const FooterQuery = (locale: string) => ({
    footer: {
        __args: {
            locale: new EnumType(locale)
        },
        slogan: true,
        button: Button,
        columns: FooterColumn,
        bottom: FooterItem,
    }
});