import React, { useEffect, useRef, useState } from 'react';
import { IButton } from "types/base";
import { INavigationItem } from "components/header/Header";
import Link from "components/base/Link";
import Button from "components/elements/Button";
import useAutoClose from "components/hooks/useAutoClose";
import NavigationItem from "components/header/desktop/navigation/NavigationItem";
import Logo from "components/base/Logo";
import Container from 'components/core/Container';
import Columns from "components/header/desktop/navigation/Columns";
import cn from "classnames";
import useRouter from "components/hooks/useRouter";

type Props = {
    callToActions: IButton[];
    items: INavigationItem[];
    hidden?: boolean
};

export default function Navigation(props: Props) {
    const { items, callToActions, hidden } = props;
    const [activeItem, setActiveItem] = useState(null);
    const { asPath } = useRouter();
    const rootEl = useRef();
    const [scrollPosition, setScrollPosition] = useState(0);

    useAutoClose(
        rootEl,
        () => {
            setActiveItem(null);
        },
        activeItem
    );

    const handleMouseClick = (item: INavigationItem) => {
        setActiveItem(activeItem === item ? null : item);
    };

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };
    
    useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if(asPath) {
            setActiveItem(null);
        }
    }, [asPath]);

    const containerCn = cn(
        "flex w-full whitespace-normal justify-between"
    );

    // todo: make dynamic
    const navStartsMovingAlong = 42.5;

    return (
        <div
            className={`hidden lg:block top-0 z-40 bg-white ${hidden ? '' : 'fixed w-full'} ${scrollPosition > navStartsMovingAlong ? 'shadow-sm pt-2' : ' pt-12'} transition-all ease-linear`}
            ref={rootEl}
        >
            <Container className={containerCn}>
                <div className={"flex self-center"}>
                    <Logo className={''}/>
                </div>
                <ul className={"flex flex-row self-center cursor-pointer"}>
                    {items && items.map((item) => (
                        <NavigationItem
                            key={item.id}
                            isActive={activeItem == item}
                            onClick={() => handleMouseClick(item)}
                            className={`py-1 my-3 rounded-full`}
                            {...item}
                        />
                    ))}
                </ul>

                <div className="flex flex-row items-center justify-end gap-x-3">
                    {callToActions && callToActions.map((button) => (
                        <Link
                            key={button.id}
                            {...button.link[0]}
                        >
                            <Button
                                id={button.id}
                                variant={button.variant}
                                size={button.size}
                                color={button.color}
                                textColor={button.textColor}
                                icon={button.icon}
                            >
                                {button.label}
                            </Button>
                        </Link>
                    ))}
                </div>
            </Container>

            {activeItem && activeItem.columns.length > 0 && (
                <Columns {...activeItem} setActive={setActiveItem}/>
            )}
        </div>
    );
}