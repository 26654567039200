import React from 'react';
import Container from "components/core/Container";
import { IColumn, IFeaturedItem } from "components/header/Header";
import Column from "components/header/desktop/navigation/Column";
import Text from "components/typography/Text";
import FeaturedItem from "components/header/desktop/navigation/FeaturedItem";

type Props = {
    id: string,
    columns: IColumn[],
    featuredItem: IFeaturedItem[]
};

export default function Columns(props: Props) {
    const { columns, featuredItem } = props;
    const hasFeaturedItem = featuredItem.length > 0;

    return (
        <div className={'absolute w-full top-8 left-0 shadow-lg -mt-4 relative'}>
            {hasFeaturedItem && (
                <div
                    className={"absolute bg-gray-50 w-full top-0 right-0 h-full hidden xl:inline-flex xl:w-1/4 2xl:w-1/3 z-0"}
                />
            )}
            <div className={'bg-white'}>
                <Container>
                    <div className={'flex bg-white flex-row gap-12 justify-between z-10 overflow-auto'}>
                        {columns && columns.map((column) => (
                            <div key={column.id} className={'mb-10'}>
                                {column.label ? (
                                    <Text
                                        color={'text-primary-600'}
                                        weight={'normal'}
                                        size={'sm'}
                                        className={`mb-3 ml-2 lg:ml-3`}

                                    >
                                        {column.label}
                                    </Text>
                                ) : (
                                    <br/>
                                )}
                                <Column items={column.items} totalAmountOfColumns={columns.length}/>
                            </div>
                        ))}
                        {featuredItem.map((fi) => (
                            <FeaturedItem key={fi.id} {...fi} />
                        ))}
                    </div>
                </Container>
            </div>
        </div>
    );
}