import React from 'react';
import { IButton, IIcon, IImage, ILink, PageSlug } from "types/base";
import DesktopHeader from "components/header/desktop/DesktopHeader";
import MobileHeader from "components/header/mobile/MobileHeader";

export type IHeader = {
    callToActions: IButton[];
    navigation: INavigationItem[];
    preHeadingLinks: ILink[];
    hideNavigation: boolean;
};

export type INavigationGroup = {
    id: string;
    label: string;
    navigationItems: INavigationItem[];
    backgroundColor: string;
    subgroup: boolean;
};

export type ISubnavigationGroup = {
    id: string;
    highlighted: boolean;
    subgroups: [{
        id: string,
        label: string,
        navigationItems: INavigationItem[];
    }]
};

export type ISubnavigation = {
    id: string;
    title: string;
    groups: ISubnavigationGroup[]
};

export type INavigationItem = {
    id: string;
    label: string;
    description: string;
    isExternalLink: boolean;
    externalUrl: string;
    page: PageSlug
    navigationGroups: INavigationGroup[]
    subnavigation: ISubnavigation[]
    columns: IColumn[]
    featuredItem: IFeaturedItem
};

export type IColumn = {
    id: string;
    label?: string;
    items: (ILabel|IButton|INavMenuItem)[]
};


export type ILabel = {
    __typename: string,
    id: string;
    label: string;
};

export type INavMenuItem = {
    __typename: string,
    id: string;
    title: string;
    description?: string;
    icon?: IIcon;
    badge?: IBadge;
    link: ILink[];
};

export type IBadge = {
    __typename: string,
    id: string;
    label: string;
};

export type IFeaturedItem = {
    id?: string;
    image?: IImage,
    title?: string;
    description?: any,
    link?: IButton[]
};

export default function Header(props: IHeader) {
    return (
        <>
            <DesktopHeader {...props} />
            <MobileHeader {...props} />
        </>
    );
}

